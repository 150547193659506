import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import { BsFillAwardFill } from 'react-icons/bs'
import { IoIosSchool } from 'react-icons/io'
import { MdOutlineFolderCopy } from 'react-icons/md'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <BsFillAwardFill className='about__icon'/>
              <h5>Experience</h5>
              <small>1.5+ Years Experience</small>
            </article>
            <article className="about__card">
              <IoIosSchool className='about__icon'/>
              <h5>Education</h5>
              <small>UP Diliman BS ECE Magna Cum Laude</small>
            </article>
            <article className="about__card">
              <MdOutlineFolderCopy className='about__icon'/>
              <h5>Projects</h5>
              <small>5+</small>
            </article>
          </div>

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat modi, voluptates beatae, ducimus ullam vel expedita maiores numquam placeat ipsum velit sit natus cupiditate, iure eligendi sequi odit! Mollitia, voluptatum?
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About