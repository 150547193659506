import React, { useRef } from 'react';
import './contact.css'
import { AiOutlineMail } from 'react-icons/ai'
import { FaFacebookMessenger } from 'react-icons/fa'
import { BsFillPhoneFill } from 'react-icons/bs'
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_sujlw19', 'template_6v2lvir', form.current, 'KVsv0iZobsevpSzZn')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <AiOutlineMail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>josephwilliamdoctor@gmail.com</h5>
            <a href="mailto:josephwilliamdoctor@gmail.com" target="_blank">Send a message</a>
          </article>
          <article className="contact__option">
            <FaFacebookMessenger className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>howie.edoctor</h5>
            <a href="https://m.me/howie.edoctor" target="_blank">Send a message</a>
          </article>
          <article className="contact__option">
            <BsFillPhoneFill className='contact__option-icon' />
            <h4>Contact Number</h4>
            <h5>+639166360294</h5>
            <a href="tel:+639166360294" target="_blank">Send a message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="email" name='email' placeholder='Your Email' required/>
          <textarea name="message" rows="7" placeholder='Your Message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact